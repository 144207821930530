<template>
  <div class="pad-home">
    <HeaderBar
      title="搜索地址"
      :showLeft="true"
    ></HeaderBar>
    <div class="searchBox">
      <!-- <van-search placeholder="请输入位置"
                  v-model="address"
                  disabled
                  readonly
      @click="search" />-->
      <input
        type="text"
        placeholder="请输入位置"
        v-bind:value="address"
        v-on:input="address = $event.target.value"
        autofocus
        class
        ref="searchInput"
        @blur.prevent="inputLoseFocus"
      />

      <div
        class="clearInput"
        @click.stop.prevent="clear"
        v-if="address"
      ></div>
    </div>
    <!-- <div
      class="search-history"
      v-if="searchHitoryList.length>0"
    >
      <div class="search-history-title">历史记录</div>
      <div
        :class="['search-history-items',{'search-history-items-border':index!=searchHitoryList.length-1}]"
        @click="selectHistory(item)"
        v-for="(item,index) in searchHitoryList"
        :key="index"
      >{{item.EndAddress}}</div>
    </div> -->
    <div class="search-list">
      <div
        class="search-list-item"
        v-for="item in predictions"
        :key="item.place_id?item.place_id:item.reference"
        @click="selectPrediction(item)"
      >{{item.description}}</div>
    </div>
  </div>
</template>

<script>
import HeaderBar from "@/components/headerbar/headerbar";
import { Search, Toast } from "vant";
import debounce from "lodash.debounce";
import {
  getGoogleMapInfo,
  addUserAddress,
  GetGoogleApCity,
  WxGoogleApiCity,
} from "@/service/";
import { getStore } from "../../lib/storage";
import { mapMutations, mapActions, mapState, mapGetters } from "vuex";
import requestUrl from "@/service/requestUrl";
import { API_KEY } from "@/config";
import { inputBlur } from "@/mixins/inputBlur";
import { setStore } from "../../lib/storage";
// import $ from 'jquery'

export default {
  name: "searchAdd",
  mixins: [inputBlur],
  components: {
    HeaderBar,
    [Search.name]: Search,
    // [List.name]: List
  },
  data() {
    return {
      address: "",
      textid: 1,
      again_res_url: "",
      again_obj: {},
      // searchList: [],
      predictions: [],
    };
  },
  computed: {
    ...mapState({
      searchList: ({ globalVal }) => globalVal.searchHitory,
      queryHistoryList: ({ globalVal }) => globalVal.queryHistory,
      transferLocation: ({ pathVal }) =>
        pathVal.transferLocation || getStore("transferAddress"),
    }),
    ...mapGetters(["searchHitoryList"]),
    position() {
      return getStore("pos");
    },
  },
  created() {
    this.debounceGetPredictions = debounce(this.getPredictions, 300);
  },
  methods: {
    ...mapMutations([
      "SET_START_PLACE",
      "SET_END_PLACE",
      "ADD_TRANSFER_LOCATION",
    ]),
    ...mapActions(["getSearchHistory"]),
    clear() {
      this.address = "";
    },
    //输入关键字
    async getPredictions(input) {
      if (input == "") {
        return;
      }
      this.again_obj.key = input;
      // eslint-disable-next-line no-undef
      // let sessionToken = new google.maps.places.AutocompleteSessionToken();
      // eslint-disable-next-line no-undef
      // let autocompleteservice = new google.maps.places.AutocompleteService();
      // autocompleteservice.getQueryPredictions({ input: input, }, (predictions, status) => {
      //   if (status === 'OK') {
      //     this.predictions = predictions;
      //     console.log(predictions)
      //   }
      // });
      // let url = `${requestUrl.autocomplete}?input=${input}&key=${API_KEY}&components=country:${getStore('countryShortName')}`
      let pos = JSON.parse(this.position);

      let latlng = "";
      if (pos) {
        latlng = `${pos.lat},${pos.lng}`;
        this.again_obj.lon = pos.lng;
        this.again_obj.lat = pos.lat;
      }
      //   else {
      //     pos.lat = "23.679503";
      //     pos.lng = "113.091692";
      //     latlng = `${pos.lat},${pos.lng}`;
      //     this.again_obj.lon = pos.lng;
      //     this.again_obj.lat = pos.lat;
      //   }

      // console.log(pos.lat)
      // let latlng = `${pos.lat},${pos.lng}`
      console.log("input:", input);
      let url = `${requestUrl.autocomplete}?input=${input}&location=${latlng}&radius=50000&key=${input}`;
      //https://maps.googleapis.com/maps/api/place/autocomplete/json?input=武大园&location=23.679503,113.091692&radius=50000&key=武大园
      //https://maps.googleapis.com/maps/api/place/autocomplete/json?input=武大园&location=23.679503,113.091692&radius=50000&key=武大园
      //   this.again_res_url = `${requestUrl.autocomplete}?input=${input}&location=${latlng}&radius=50000&key=${input}`;
      this.again_res_url = url;
      let idCode = JSON.parse(getStore("userInfo")).IDCode;
      // console.log(idCode)
      // console.log(input)
      let ip = 101;
      let res = await getGoogleMapInfo(url, idCode, input, ip);

      res = JSON.parse(res.data);
      //  console.log(res)
      if (res) {
        // console.log(res);
        // console.log(this.queryHistoryList);
        this.predictions = res.predictions;
        // this.queryHistory= res.predictions;

        // console.log(this.queryHistoryList.length);
      }
      // }else{
      // let res = await autocompleteAdd(input,latlng,API_KEY2)
      // this.predictions = res.predictions;
      // console.log(res)
      //  let googleData = await autocompleteAdd({
      //           input: input,
      //           location:latlng,
      //           radius:50000,
      //           key: API_KEY2,

      //       }, function(success) {
      //          console.log("success-----", success);
      //       },function(fail) {
      //           console.log("fail------", fail)
      //       })
      //       console.log(googleData)
      //   const API_PROXY = "https://bird.ioliu.cn/v1/?url=";
      // let url = `${requestUrl.autocomplete}?input=${input}&location=${latlng}&radius=50000&key=${API_KEY2}`;
      // axios.get(API_PROXY + url).then(d => {})
      //   $.ajax({
      //     type : 'GET',
      //     url  :"https://bird.ioliu.cn/v1/?url=https://maps.googleapis.com/maps/api/place/autocomplete/json?input=光谷&location=30.4723547,114.3933668&radius=50000&key=AIzaSyDi1ZQI8QezeTP_3p5i2MDKtn4GytFkIwg",
      //     crossDomain:true,
      //     dataType : 'json',
      //     contentType:false,
      //     processData:false,
      //     success : function(res) {
      //         console.log(res);
      //     },
      //     error : function(msg) {
      //        console.log(msg);
      //     }
      // });
    },
    // 点击某个位置
    async selectPrediction(item) {
      console.log("item:", item);
      let type = this.$route.params.type;
      let address = this.$route.params.address;
      let _place_id;
      //   if (this.textid == 1) {
      //     _place_id =
      //       "Eio0NzUxIE1jY2xlbGxhbmQgUm9hZCwgUmljaG1vbmQsIEJDLCBDYW5hZGEiMRIvChQKEgkjkw0IO3WGVBHw-AbXko_nChCPJSoUChIJQXgJEzt1hlQRvXUD9uv1lpg";
      //   } else {
      //     _place_id = item.place_id ? item.place_id : item.reference;
      //   }
      _place_id = item.place_id ? item.place_id : item.reference;
      //       let _place_id =
      //         "Eio0NzUxIE1jY2xlbGxhbmQgUm9hZCwgUmljaG1vbmQsIEJDLCBDYW5hZGEiMRIvChQKEgkjkw0IO3WGVBHw-AbXko_nChCPJSoUChIJQXgJEzt1hlQRvXUD9uv1lpg";

      let url = `${requestUrl.geocode}?place_id=${_place_id}&key=${API_KEY}`;
      let idCode = JSON.parse(getStore("userInfo")).IDCode;
      let keys = _place_id;
      let ip = 101;
      console.log("textidtextid:", this.textid);
      // console.log(item.place_id)
      //       $.ajax({
      //     url : `${requestUrl.geocode}?place_id=${item.place_id}&key=${API_KEY2}`,
      //     type : 'GET',
      //     dataType : 'json',
      //     success : function(res) {
      //         console.log(res);
      //     },
      //     error : function(msg) {
      //        console.log(msg);
      //     }
      // });
      //推荐位置
      let res = await getGoogleMapInfo(url, idCode, keys, ip);
      console.log("2222res:", res.code, res);
      if (res.code == -1) {
        Toast("请再点一次");

        let _again_obj = this.again_obj;
        console.log("_again_obj:", _again_obj);
        // console.log("_url2:", encodeURIComponent(_url));
        // let again_res = await GetGoogleApCity(_url, idCode, keys, ip);
        let again_res = await WxGoogleApiCity(
          _again_obj.key,
          _again_obj.lon,
          _again_obj.lat
        );
        console.log("again_res:", again_res);
        this.textid++;
        if (again_res.status == "OK") {
          let results = again_res.results[0];
          console.log("results:", results);
          let latlng = results.geometry.location;
          let place = {
            description: item.description,
            latlng: results.geometry.location,
          };
          // console.log(place)
          if (address === "1") {
            this.SET_START_PLACE(place);
            this.$router.push(`/${type}`);
          } else if (address === "2") {
            this.SET_END_PLACE(place);
            this.$router.push(`/${type}`);
          } else if (address === "3") {
            let _place = {
              city: item.description,
              geo: results.geometry.location,
            };
            localStorage.setItem("indexWhich", this.$route.query.indexWhich);
            this.ADD_TRANSFER_LOCATION(_place, this.$route.query.indexWhich);
            this.$router.push(`/${type}`);
          } else if (address === "0") {
            let Umid = JSON.parse(getStore("userInfo")).ID;
            this.addAddress(
              JSON.stringify(latlng),
              item.description,
              Umid
            ).then((res) => {
              if (again_res.code == 1) {
                this.$router.go(-1);
              } else {
                Toast("添加地址失败");
              }
            });
            // console.log(result)
          }
        }
      }

      res = JSON.parse(res.data);
      if (res.status == "OK") {
        // console.log(res)
        let results = res.results[0];
        // console.log(results.geometry)
        // let latlng = {
        //   "lat": results.geometry.location.lat(),
        //   "lng": results.geometry.location.lng()
        // }
        console.log("results:", results);
        let latlng = results.geometry.location;
        // let _description = results.description;
        // console.log(latlng)
        let place = {
          description: item.description,
          latlng: results.geometry.location,
        };
        // console.log(place)
        if (address === "1") {
          this.SET_START_PLACE(place);
          this.$router.push(`/${type}`);
        } else if (address === "2") {
          this.SET_END_PLACE(place);
          this.$router.push(`/${type}`);
        } else if (address === "3") {
          let _place = {
            city: item.description,
            geo: results.geometry.location,
          };
          localStorage.setItem("indexWhich", this.$route.query.indexWhich);
          this.ADD_TRANSFER_LOCATION(_place, this.$route.query.indexWhich);
          this.$router.push(`/${type}`);
        } else if (address === "0") {
          let Umid = JSON.parse(getStore("userInfo")).ID;
          this.addAddress(JSON.stringify(latlng), item.description, Umid).then(
            (res) => {
              if (res.code == 1) {
                this.$router.go(-1);
              } else {
                Toast("添加地址失败");
              }
            }
          );
          // console.log(result)
        }
      }
    },
    async addAddress(Region, Address, Umid) {
      let result = await addUserAddress(Region, Address, Umid);
      console.log(Region);
      return result;
    },
    selectHistory(item) {
      let type = this.$route.params.type;
      let address = this.$route.params.address;
      let place = {
        description: item.EndAddress,
        latlng: eval("(" + item.EndGeo + ")"),
      };
      if (address === "1") {
        this.SET_START_PLACE(place);
        this.$router.push(`/${type}`);
      } else if (address === "2") {
        this.SET_END_PLACE(place);
        this.$router.push(`/${type}`);
      } else if (address === "3") {
        let _place = {
          city: item.EndAddress,
          geo: eval("(" + item.EndGeo + ")"),
        };
        console.log("search3333333:", _place);
        localStorage.setItem("indexWhich", this.$route.query.indexWhich);
        this.ADD_TRANSFER_LOCATION(_place, this.$route.query.indexWhich);
        this.$router.push(`/${type}`);
      } else if (address === "0") {
        let Umid = JSON.parse(getStore("userInfo")).ID;
        this.addAddress(
          JSON.stringify(eval("(" + item.EndGeo + ")")),
          item.EndAddress,
          Umid
        ).then((res) => {
          if (res.code == 1) {
            this.$router.go(-1);
          } else {
            Toast("添加地址失败");
          }
        });
      }
    },
  },
  watch: {
    address: function () {
      this.debounceGetPredictions(this.address);
    },
  },
  mounted() {
    let umid = JSON.parse(getStore("userInfo")).ID;
    this.getSearchHistory(umid);
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/mixin.less";

.pad-home {
  padding-top: 94px;
}
.searchBox {
  margin: 22px 30px 40px;
  padding: 0 20px;
  height: 80px;
  background: rgba(238, 238, 238, 1);
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    width: 80%;
    height: 42px;
    padding-left: 60px;
    background: rgba(238, 238, 238, 1) url("../../assets/search@2x.png") 0
      center no-repeat;
    background-size: 40px 40px;
    .fontSize(30px);
    line-height: 42px;
  }
}

.clearInput {
  .size(80px, 80px);
  background: url("../../assets/delete@2x.png") center right no-repeat;
  background-size: 24px 24px;
}

.search {
  &-history {
    padding: 0 30px;
    border-bottom: 20px solid rgba(238, 238, 238, 1);

    &-title {
      font-size: 30px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: rgba(204, 204, 204, 1);
      line-height: 42px;
    }

    &-items {
      height: 102px;
      font-size: 30px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 102px;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &-border {
        border-bottom: 2px solid rgba(238, 238, 238, 1);
      }
    }
  }

  &-list {
    padding: 0 30px;
    &-item {
      // height: 102px;
      padding: 30px 0;
      font-size: 30px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      line-height: 42px;
      color: rgba(51, 51, 51, 1);
      border-bottom: 2px solid rgba(238, 238, 238, 1);
    }
  }
}
</style>
